<template>
  <div v-if="article?.content">
    <div class="relative h-[500px] lg:h-[700px] w-full overflow-hidden">
      <img :src="imageUrl(article.background.path)" :alt="article.title" class="article__header-blur">
      <picture v-if="article?.background && article?.title">
        <img :src="imageUrl(article.background.path)" :alt="article.title" 
        class="article__header-bg">
      </picture>
    </div>
    <div class="container mx-auto px-4 my-7">
      <h1 class="text-xl font-semibold">{{article.title}}</h1>
      <QuillEditor class="article__container" v-model:content="articleContent" :options="options" />   
    </div>
  </div>
</template>

<script setup>
  import { computed, inject, onBeforeMount } from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import moment from "moment";
  const {dispatch, getters} = useStore();
  const {params:{articleId}} = useRoute();

  const article = computed( () => getters['news/getById'](articleId));
  const articleContent = computed( () => {
    return typeof article.value.content === 'string' ? JSON.parse(article.value.content) : article.value.content;
  });

  const options = {
    readOnly: true,
    modules:{
      toolbar: false
    }
  }
  const imageUrl = inject('imageUrl');
  onBeforeMount( async () => {
    if(!article.value){
       await dispatch('news/getById', articleId);
    }
  })
</script>

<style lang="scss">
  .article{
    &__header{
      &-bg{
        @apply absolute inset-0 top-1/2 -translate-y-1/2 object-contain brightness-75
        w-full h-full z-10;
        object-position: center 0px;
      }
      &-blur{
        @apply absolute inset-0 object-cover brightness-[45%] blur-lg w-full h-full z-0;
      }
    }
    &__container{
      &.ql-container.ql-snow{
        @apply border-none;
      }
      h1,h2,h3,h4,h5{
        @apply capitalize text-black;
      }
      img{
        @apply max-h-[500px] object-cover;
      }
      p{
        @apply text-sm md:text-base lg:text-lg lg:leading-6 mb-2;
        &:first-of-type{
          @apply text-lg lg:text-lg leading-8 font-medium;
        }
      }
      ul{
        @apply mb-2;
        li{
          @apply text-sm md:text-base lg:text-[18px] lg:leading-6;
        }
      }
    }
    &__footer{
      @apply flex flex-col gap-5 px-4
      lg:flex-row lg:items-center lg:justify-between;
      &-author{
        @apply text-sm
        flex gap-3 lg:mb-0
        lg:text-lg lg:gap-7;
        strong{
          @apply font-extrabold;
        }
      }
    }
  }
</style>